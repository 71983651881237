.auth__form{
    background: #fff;
    color: var(--primary-color);
    padding: 40px !important;
    border-radius: 5px !important;

}

.auth__btn{
    background: var(--primary-color) !important;
    color:#fff;
    font-weight: 600 !important;
    width: 20px;
    height: 50px;
    border-radius: 10px !important;
}

.auth__form p {
    margin-top: 30px;
}

.auth__form input[type="file"]{
    cursor: pointer;
}

.auth__form input[type="text"]{
    width: 100%;
    padding: 15px;
}

.auth__form input[type="password"]{
    width: 100%;
    padding: 15px;
}

.auth__form input[type="email"]{
    width: 100%;
    padding: 15px;
}

.image-container{
    
    background-size: cover;
    background-repeat: no-repeat;
    align-items: center;
  }
  
  

  .profile-img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    margin-bottom: 10px;
  }
  
  .picture-img {
    width: 190px;
    height: 150px;
    object-fit: cover;
    margin-bottom: 50px;
    border-radius: 50px;
  }


  .idcard{
    margin-top: 20px;
  }