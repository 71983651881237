.logo {
    display: flex;
    align-items: center;
    margin: 0 20px;
  }
  
  .logo img {
    width: 80px;
    height: 50px;
  }
  
  .logo h1 {
    font-size: 2rem;
    margin-left: 10px;
    font-weight: bold;
    color: #333;
  }
  