*,
*::after,
*::before {
  margin: 0 ;
  box-sizing: border-box;
}
html {
  font-size: 62.5% ;
}
body {
  font-size: 1.6rem ;
}
li {
  list-style: none !important;
}
a {
  text-decoration: none !important;
  color: #111 !important;
  font-size: 1.6rem;
  font-weight: bold;
}

.nav {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 8vh;
  background: #fff;
  position: relative;
  z-index: 9999;
  margin-top: 10px;;
}
.nav__brand {
  text-transform: uppercase;
}

.nav__menu {
  display: flex;
  align-items: center;
  justify-content: space-around;
  gap: 3rem;
}
.nav__toggler {
  display: none;
}
.nav__toggler div {
  width: 2.5rem;
  height: 0.2rem;
  margin: 0.4rem;
  background: #111;
  transition: 0.3s ease-in;
}

@media screen and (max-width: 768px) {
  .nav__toggler {
    display: block;
    cursor: pointer;
  }
  .nav__menu {
    position: fixed;
    top: 7vh;
    right: 0;
    height: 93vh;
    width: 50vw;
    background: #fff;
    flex-direction: column;
    transform: translateX(100%);
    transition: 0.5s ease-in;
  }
}
/* Active Class */
.nav__active {
  transform: translateX(0%);
}

/* Toggle Icon Animation */

.toggle .line1 {
  transform: rotate(-45deg) translate(-4px, 5px);
}
.toggle .line2 {
  opacity: 0;
}
.toggle .line3 {
  transform: rotate(45deg) translate(-4px, -5px);
}

