@media only screen and (max-width: 768px) {
  .quote-container {
    flex-direction: column;
  }

  .first-column {
    width:100%;
  }
  
    .header {
    display: none;
  }
  
  .header1 {
    display: none;
    margin-left: 2px;
    margin-right:1px;
    width:100%;
  }
  
  .mobileQuote{
      display: block;
      
  }

  .second-column {
    display: none;
  }
}

/* Hide on larger devices */
@media only screen and (min-width: 768px) {
  .header1 {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  h4 {
    display: none;
  }
}