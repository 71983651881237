/* AddOrder styles */
section {
    padding: 50px 0;
  }
  
  .form__group {
    margin-bottom: 30px;
  }
  
  .form__group input,
  .form__group select {
    display: block;
    width: 100%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 16px;
    font-family: inherit;
    color: #555;
  }
  
  .form__group span {
    display: block;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: bold;
  }
  
  .buy__btn {
    display: inline-block;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color: #007bff;
    color: #fff;
    font-size: 16px;
    cursor: pointer;
  }
  
  .buy__btn:hover {
    background-color: #0069d9;
  }
  
  .loading {
    font-size: 24px;
    text-align: center;
    margin-top: 50px;
  }
  