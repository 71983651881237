.container {
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
  padding: 2rem;
}

h1 {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 2rem;
}

.form-group {
  margin-bottom: 1.5rem;
  text-align: left;
}

label {
  display: block;
  margin-bottom: 0.5rem;
}

.form-control {
  width: 100%;
  border-radius: 15px;
  border: 2px solid  #ADD8E6;
  font-size: 1rem;
  outline: none;
  
  
  padding: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
}

.btn {
  border-radius: 15px;
  font-size: 1rem;
  cursor: pointer;
  width: 100%;
  margin-top: 20px;
  padding: 10px;
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.2);
  font-weight: bold;
  text-transform: capitalize;
  transition: all 0.2s ease-in-out;
}

.btn-primary {
  background-color: #007bff;
  color: #fff;
  border: none;
}

.btn-primary:hover {
  background-color: #0062cc;
}


@media only screen and (max-width: 768px) {
  .form-control {
    font-size: 14px;
  }
  
  .btn-primary {
    font-size: 14px;
    width: 100%;
    margin-top: 0px;
  }
  
  h1{
      display: none;
  }
}

@media only screen and (max-width: 576px) {
  .form-control {
    font-size: 12px;
  }
  
  .btn-primary {
    margin-top: 0px;
    font-size: 12px;
    padding: 8px 16px;
    width: 100%;
  }
  
  h1{
      display: none;
  }
}


@media only screen and (min-width: 768px) {
    h3{
        display: none;
    }
}