@media screen and (max-width: 768px) {
  .detail {
    display: none !important;
  }

  p{
    display: none !important;
  }
}


@media screen and (min-width: 768px) {
  .mini {
    display: none !important;
  }

  h5{
    display: none !important;
  }
}
